#menu {
  border-radius: 0.35rem 0px 0px 0.35rem;
  background-color: #dbdbdb;
  width: 20%;
  min-width: 20%;
  overflow: hidden;
  border-right: 1px solid grey;
}

.menuSelector {
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid grey;
}

.menuSelector.activated {
  background-color: #ce282b;
  text-transform: uppercase;
  color: white;
}

.about,
.about_2 {
  text-align: justify;
  width: 40%;
  padding: 2%;
}

.about h3,
.about_2 h3 {
  text-align: center;
  margin-top: 1rem;
  color: #ce282b;
}

.about li,
.about_2 li {
  margin-top: 0.5rem;
  margin-left: 3rem;
}

.text {
  margin-top: 1rem;
  text-indent: 2rem;
}

@media only screen and (max-width: 768px) {
  #menu,
  .menuSelector {
    width: 100dvw;
  }

  .about,
  .about_2 {
    width: 100dvw;
    padding: 0dvw 10dvw 5dvw 10dvw;
  }
}
