#Header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;
  height: 6rem;

  width: 100dvw;
  max-width: 100%;

  background-color: white;
  box-shadow: 0px 3px 3px #00000033;

  overflow: hidden;
}

#Header .content {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;

  width: 70%;
  height: 6rem;
  margin: 0 auto;
}

.content .menus {
  display: flex;
}

.content img {
  height: 5rem;
}

.menus a {
  color: black;
}

.menu {
  position: relative;
  cursor: pointer;
  width: 130px;
  text-align: center;
  font-size: 1.25rem;
  margin-right: 2rem;
  padding: 8px;
  overflow: hidden;

  border: 1px solid #ce282b;
  transition: all 0.35s;
}

.menu:hover {
  color: white;
}

.menu:before {
  position: absolute;
  top: 55px;
  left: 55px;
  content: "";
  width: 200%;
  height: 500%;
  background-color: #ce282b;
  z-index: -5;
  transform: rotate(45deg);
  transition: all 0.5s;
}

.menu:hover:before {
  top: -80px;
  left: -50px;
}

.menu.ponto {
  border-color: #59cbe8;
}

.menu.ponto:before {
  background-color: #59cbe8;
}

/*  DESKTOP */
svg.desktop {
  display: none;
}

.content:has(.desktop) {
  justify-content: space-between;
}

.content:has(.desktop) .menus {
  flex-direction: row;
  gap: 0.5rem;
}

.content:has(.desktop) .menu {
  gap: 2rem;
}

/* MOBILE */

#Header:has(.mobile) {
  transition: height 0.5s;
}

#Header:has(.mobile) :before {
  display: none;
}

#Header .content:has(.mobile) {
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 20%;

  padding: 0.5rem 0rem;
}

#Header:has(.mobile.menuOpen) {
  height: 13rem;
}

.content:has(.mobile) img {
  margin-left: 5dvw;
}

.content:has(.mobile) svg {
  margin-right: 5dvw;
}

.content:has(.mobile) .menus {
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.content:has(.mobile) .menus .menu {
  width: 50dvw;
  margin: 0;
}

.menus:has(.menuClosed) {
  height: 0px;
}

.menus.mobile .menu {
  width: 100%;
}
