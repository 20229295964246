h2 {
  text-align: center;
  color: #ce282b;
}

.content {
  width: 100%;

  margin: 1rem auto;
  text-align: center;
}

.icons {
  display: flex;
  max-width: 60%;
  justify-content: space-around;
  margin: 50px auto 10px auto;
  cursor: pointer;
}

.icons.mobile {
  max-width: unset;
}

.icons.mobile img {
  height: 100px;
  margin-bottom: 1rem;
}

.icons img {
  height: 150px;
}

label {
  margin-top: 15px;
  cursor: pointer;
}

.AccessWrapper {
  display: flex;
  flex-direction: column;
}

.content + button {
  border: 0;
  padding: 5px 10px 0px 10px;

  color: #ce282b;
  font-size: 2rem;

  opacity: 0;
  transition: opacity 0.5s;
}

.content + button:hover {
  opacity: 1;
}

.auxilioSuporte {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  width: 80%;
}

.auxilioSuporte a {
  color: black;
  padding: 0.5rem 2rem;
  border: solid #ce282b 2px;
  border-radius: 3rem;
  display: inline-block;
}

@media only screen and (max-width: 1366px) {
  .icons img {
    height: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .auxilioSuporte {
    display: flex;
    flex-direction: column;
  }

  .auxilioSuporte a {
    margin-bottom: 1rem;
    text-align: center;
  }
}
