.content {
  width: 80%;

  margin: 1rem auto;
}

.content p {
  font-size: 1.15rem;
  text-align: justify;
  margin-bottom: 1.5rem;
}
