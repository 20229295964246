* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: white;
}

body {
  background: #e0e3e7;
  /* background: #fcbf2d55;
  background: -moz-linear-gradient(180deg, #fcbf2d33 0%, #fcbf2dff 100%);
  background: -webkit-linear-gradient(180deg, #fcbf2d33 0%, #fcbf2dff 100%);
  background: linear-gradient(180deg, #fcbf2d33 0%, #fcbf2dff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcbf2d33",endColorstr="#fcbf2dFF",GradientType=1); */
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.App-header {
  margin-top: 3dvh;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.about {
  text-align: justify;
  font-size: 1.15rem;
  text-indent: 3rem;
}

#divContainer {
  margin-top: 10dvh;
}

.buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}

.buttons button {
  margin-top: 1rem;
  width: 100%;
  transition-property: color, background-color;
  transition-duration: 0.25s;
}

.buttons a {
  display: inline-block;
  width: 70%;
}

.buttons button:hover {
  background-color: #ce282b;
  color: white;
}

button {
  font-size: 1.5rem;
  background: none;
  border: 3px #ce282b solid;
  border-radius: 5px;
  cursor: pointer;
}

.downBtn {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  height: 2.2rem;
  width: 2.2rem;

  background-color: #ce282b;
  color: white;
  transform: rotate(90deg);
}

#Slideshow-container .react-slideshow-container .nav {
  height: 100%;
  width: 65px;
  border-radius: 0;
  opacity: 0.4;
  transition: opacity 0.5s;
}

#Slideshow-container .react-slideshow-container .nav:hover {
  opacity: 1;
}

#Downloads:before,
#Contatos:before {
  content: "";
  display: block;
  position: relative;
  top: -500px;
  width: 0;
  height: 5em;
  margin-top: -5em;
}

.view {
  display: flex;
  justify-content: space-around;
  margin: 5dvh auto;

  width: 90dvw;
}
