.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10%;
  padding: 0 50px;

  margin: 0 auto;
}

.buttons.mobile {
  padding: 0;
  justify-content: center;
}

.buttons button {
  position: relative;

  border-radius: 0;
  padding: 0.5rem;
  min-width: 400px;
  width: 28dvw;

  background-color: unset;
  border: 1px solid #ce282b;

  margin: 20px 10px;

  z-index: 5;
  overflow: hidden;

  transition: color 0.5s;
}

.buttons.mobile button {
  margin: 1rem 0;
  min-width: unset;
  width: 90dvw;
}

.buttons button:hover {
  color: white;
}

.buttons button:before {
  position: absolute;
  top: 160%;
  right: -100%;
  content: "";
  width: 50dvw;
  height: 1000%;
  background-color: #ce282b;
  z-index: -1;
  transform: rotate(-45deg);
  transition: all 0.5s;
}

.buttons.mobile button:before {
  display: none;
}

.buttons.mobile button:hover {
  color: black;
}

button:hover:before {
  top: -100%;
  right: -50px;
}
