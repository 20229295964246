.Footer {
  background-color: #ce282b;
  box-shadow: 0px -3px 8px #00000077;
  width: 100dvw;
  max-width: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  color: white;

  width: 100dvw;
  max-width: 100%;
}

.address,
.phone,
.email {
  font-size: 1rem;
  text-align: center;
}

.Footer.mobile .content {
  display: flex;
  flex-direction: column;
}

.Footer.mobile .content div {
  margin: 0.7rem 0;
}
