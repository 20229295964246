.card {
  margin: 3rem auto;

  background-color: aliceblue;
  border-radius: 0.35rem;
  box-shadow: 0px 2px 7px #000000bb;

  width: 80dvw;
  padding: 2rem;
}

.card.mobile {
  margin: 3rem 0;
  width: 100%;
  padding: 2rem 0.3rem;

  flex-direction: column;
}

.card.sections {
  display: flex;

  padding: 0;
}
