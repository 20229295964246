.sectionCardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 70dvw;

  margin: 0 auto;
}

.sectionCard {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  /* background-color: white; */
  cursor: pointer;

  width: 18dvw;
  margin: 2rem;

  padding: 1rem;

  border-radius: 8px;

  text-align: justify;
  /* box-shadow: 0px 2px 7px #000000bb; */
}

.sectionCard h3 {
  color: #202020;
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
}

.sectionCard h3:after {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;

  background-color: #fcbf2d;
}

.iconStyleWrapper {
  position: relative;
  z-index: 2;
  border-radius: 50%;

  background-color: #fcbf2d;

  padding: 1.3rem;

  overflow: hidden;
}

.iconStyleWrapper:before {
  content: "";
  width: 0%;
  height: 0%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  background-color: #ce282b;

  border-radius: 50%;

  transition: all 0.25s;
}

.iconStyleWrapper:hover:before {
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.iconStyleWrapper svg {
  transition: all 0.25s;
}

.iconStyleWrapper:hover svg {
  color: white;
}

.sectionCard .iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .sectionCardWrapper {
    width: 100dvw;
  }

  .sectionCard {
    width: 45dvw;
    margin: 0;
    padding: 2.5dvw;
  }
}
